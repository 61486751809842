import { Control, useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import MultipleChoiceAndSelectQuestion, {
  MultipleChoiceQuestion,
  MultipleSelectQuestion,
} from './MultipleChoiceAndSelectQuestion';
import { useEffect, useState } from 'react';
import { max50Caracters } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import FancyTextArea from '../FancyTextArea';

interface LongAnswerQuestion {
  questionType: 'LONG_ANSWER';
}

interface ShortAnswerQuestion {
  questionType: 'SHORT_ANSWER';
  correctAnswer: string;
}

export interface QuizQuestionFormChoice {
  id?: number;
  differentiation: boolean;
  questionText: string;
  requiresFile: boolean;
  solutions: string;
  questionType: 'MULTIPLE_CHOICE' | 'MULTIPLE_SELECT';
  questionValue: MultipleSelectQuestion | MultipleChoiceQuestion;
}

export interface QuizQuestionFormText {
  id?: number;
  differentiation: boolean;
  questionText: string;
  requiresFile: boolean;
  solutions: string;
  questionType: 'SHORT_ANSWER' | 'LONG_ANSWER';
  questionValue: ShortAnswerQuestion | LongAnswerQuestion;
}

export type QuizQuestionForm = QuizQuestionFormChoice | QuizQuestionFormText;

interface QuizQuestionProps {
  defaultValues: QuizQuestionForm | null;
  differentiation: boolean;
  requiresFiles: boolean;
  onSubmit: (value: QuizQuestionForm) => void;
  editModeActive: () => void;
  deleteQuestion: () => void;
}

const QuizQuestion = ({
  defaultValues = null,
  onSubmit,
  requiresFiles,
  editModeActive,
  deleteQuestion,
  differentiation,
}: QuizQuestionProps) => {
  const { t } = useTranslation('global');

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
      setEditMode(false);
    }
  }, [defaultValues]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    control,
    unregister,
    formState: { errors },
  } = useForm<QuizQuestionForm>({
    defaultValues: {
      differentiation,
      ...(!defaultValues
        ? {
            questionType: 'MULTIPLE_CHOICE',
          }
        : {
            ...defaultValues,
          }),
    },
  });

  const [editMode, setEditMode] = useState(true);
  const questionType = watch('questionType');

  const submitForm = (data: QuizQuestionForm) => {
    setEditMode(false);
    onSubmit(data);
  };

  const activateEditMode = () => {
    editModeActive();
    setEditMode(true);
  };

  useEffect(() => {
    if (questionType === 'MULTIPLE_CHOICE' || questionType === 'SHORT_ANSWER') {
      if (!getValues('questionValue.correctAnswer')) {
        setValue('questionValue.correctAnswer', '');
      }
      unregister('questionValue.correctAnswers');
    } else if (questionType === 'MULTIPLE_SELECT') {
      if (!getValues('questionValue.correctAnswers')) {
        setValue('questionValue.correctAnswers', []);
      }
      unregister('questionValue.correctAnswer');
    } else {
      unregister('questionValue.correctAnswer');
      unregister('questionValue.correctAnswers');
      unregister('questionValue.choices');
    }
    setValue('questionValue.questionType', questionType);
  }, [questionType]);

  const returnCorrectQuestionType = () => {
    if (questionType === 'MULTIPLE_CHOICE') {
      return (
        <MultipleChoiceAndSelectQuestion
          select={false}
          register={register as UseFormRegister<QuizQuestionFormChoice>}
          errors={errors}
          control={control as Control<QuizQuestionFormChoice>}
          watch={watch as UseFormWatch<QuizQuestionFormChoice>}
        />
      );
    } else if (questionType === 'MULTIPLE_SELECT') {
      return (
        <MultipleChoiceAndSelectQuestion
          select={true}
          register={register as UseFormRegister<QuizQuestionFormChoice>}
          errors={errors}
          control={control as Control<QuizQuestionFormChoice>}
          watch={watch as UseFormWatch<QuizQuestionFormChoice>}
        />
      );
    }

    switch (questionType) {
      case 'SHORT_ANSWER':
        return (
          <div className='mb-3'>
            <label htmlFor='correctAnswer' className='form-label'>
              {t('quiz.correctAnswer')}
            </label>
            <input
              type='text'
              className='form-control'
              id='correctAnswer'
              {...register('questionValue.correctAnswer', { required: true })}
            />
            {errors.questionValue?.questionType && (
              <span className='invalid-feedback'>{t('quiz.correctAnswerRequired')}</span>
            )}
          </div>
        );
      case 'LONG_ANSWER':
        return <div></div>;
    }
  };

  return editMode ? (
    <div className='card p-3 mt-0'>
      <form onSubmit={handleSubmit(submitForm)}>
        <>
          <div className='mb-3'>
            <label htmlFor='question' className='form-label'>
              {t('question')}
            </label>
            <input
              type='text'
              className='form-control'
              id='question'
              {...register('questionText', { required: true })}
            />
            {errors.questionText && <span className='text-danger'>{t('question.required')}</span>}
          </div>
          <div className={`mb-3`}>
            <label htmlFor='questionType' className='form-label'>
              {t('questionType')}
            </label>
            <select
              className='form-select'
              id='questionType'
              {...register('questionType', { required: true })}
            >
              <option value='MULTIPLE_CHOICE'>{t('multipleChoice')}</option>
              <option value='MULTIPLE_SELECT'>{t('multipleSelect')}</option>
              <option value='SHORT_ANSWER'>{t('shortAnswer')}</option>
              <option value='LONG_ANSWER'>{t('longAnswer')}</option>
            </select>
            {errors.questionType && (
              <span className='text-danger'>{t('questionType.required')}</span>
            )}
          </div>
          <div className='mb-3'>{returnCorrectQuestionType()}</div>
          <div className='mb-3'>
            <label htmlFor='conceptDifferentiationLearning' className='form-label'>
              {t('solutions')}
            </label>
            <FancyTextArea
              id='solutions'
              defaultValue={''}
              name='solutions'
              required={false}
              control={control}
            />
          </div>
          {requiresFiles && (
            <div className='mb-3 form-check'>
              <label htmlFor='requiresFile' className='form-check-label'>
                {t('requiresFile')}
              </label>
              <input
                type='checkbox'
                className='form-check-input'
                id='requiresFile'
                {...register('requiresFile')}
              />
            </div>
          )}
          <div className='d-flex gap-2'>
            <button type='submit' className='btn btn-primary'>
              {t('question.save')}
            </button>
            <button onClick={deleteQuestion} type='button' className='btn btn-danger'>
              {t('question.delete')}
            </button>
          </div>
        </>
      </form>
    </div>
  ) : (
    <div className='card p-3 mt-0'>
      <div className='d-flex align-items-center'>
        <div className='me-1'>{max50Caracters(watch('questionText'))} -</div>
        <button onClick={activateEditMode} className='btn-link'>
          {t('question.edit')}
        </button>
        <button onClick={deleteQuestion} type='button' className='btn btn-link text-danger'>
          {t('question.delete')}
        </button>
      </div>
    </div>
  );
};

export default QuizQuestion;
