import { useEffect, useState } from 'react';
import { getParticipant, updateAvatar } from '../../../utils/httpRequests';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useTranslation } from 'react-i18next';

const AvatarSelection = () => {
  const { t } = useTranslation('global');
  const { participantId } = useParams<{ participantId: string }>();
  const { courseId } = useParams<{ courseId: string }>();
  const avatars = [
    {
      name: 'Davy Jones',
      image: 'DavyJones.png',
      description: 'A fearsome pirate lord of the sea!',
    },
    {
      name: 'Jack Sparrow',
      image: 'JackSparrow.png',
      description: 'The cunning Captain of the Black Pearl.',
    },
  ];

  const [selectedAvatar, setSelectedAvatar] = useState(avatars[0]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleSelect = async () => {
    const response = await updateAvatar({
      id: Number(participantId),
      avatar: selectedAvatar.image,
    });
    if (response) {
      navigate('/courses/' + courseId);
    }
  };

  useEffect(() => {
    const fetchParticipant = async () => {
      setIsLoading(true);
      console.log('initial');
      const participant = await getParticipant(participantId!);

      if (participant) {
        if (!(!participant.avatar || participant.avatar === '')) {
          navigate('/courses/' + courseId);
        }
        setIsLoading(false);
      }
    };

    fetchParticipant();
  }, [participantId]);

  return (
    <div className='d-flex flex-column h-100'>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div
            style={{
              height: '65%',
              backgroundImage: `url(${process.env.PUBLIC_URL}/ship.png)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className='d-flex flex-column'
          >
            <div
              className='py-3'
              style={{
                backgroundColor: '#000000ca',
              }}
            >
              <h1 className='text-center text-white p-0'>Select your Avatar!</h1>
            </div>
            <div className='d-flex flex-lg-row flex-grow-1 h-50 p-5'>
              <div className='w-50 h-100 d-flex align-items-center justify-content-center'>
                <img
                  src={`${process.env.PUBLIC_URL}/${selectedAvatar.image}`}
                  alt='Avatar'
                  style={{
                    objectFit: 'contain',
                    width: 'auto',
                    height: '100%',
                  }}
                />
              </div>
              <div
                className='w-50 h-100 d-flex align-items-center justify-content-center flex-column'
                style={{
                  backgroundColor: '#000000ca',
                }}
              >
                <h1 className='text-center text-white p-0'>{selectedAvatar.name}</h1>
                <p className='text-center text-white p-0'>{selectedAvatar.description}</p>
                <button
                  className='d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill'
                  onClick={handleSelect}
                >
                  {t('select')}
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              height: '35%',
            }}
            className='d-flex flex-column'
          >
            <div className='h-100 overflow-x-scroll'>
              <div className='row flex-row flex-nowrap h-100'>
                {avatars.map((avatar, index) => (
                  <div
                    key={index}
                    className='p-3 h-100 col-6 col-md-3 col-lg-2 d-flex align-items-center'
                    onClick={() => setSelectedAvatar(avatar)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/${avatar.image}`}
                      alt={avatar.name}
                      style={{
                        width: 'auto',
                        maxHeight: '100%',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AvatarSelection;
