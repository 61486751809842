import { fetchAPI } from '../../utils/httpRequests';
import {
  Quest,
  QuestLog,
  QuestLogStatus,
  QuestStep,
  QuestStepLog,
  Story,
} from '../teacher/quests/Quests';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import { ImagePinContainer } from 'react-image-pin';
import defaultMap from '../teacher/quests/default-map.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFlag, faSignature, faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { ImagePin } from 'react-image-pin/dist/components/ImagePinContainer';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import StudentQuestStepModal from '../../components/modals/StudentQuestStepModal';
import { getPinBackgroundColor, getPinIcon, startEndPins } from '../teacher/quests/QuestPage';
import StartEndModal, { BasicTextWizardStepData } from '../teacher/quests/modals/StartEndModal';
import { useTranslation } from 'react-i18next';

export const isIdStartOrEnd = (id: string) => id === 'start' || id === 'end';

export const statusFromQuestStepLogs = (questLogs: QuestStepLog[] | undefined): QuestLogStatus => {
  if (!questLogs || !questLogs.length) {
    return 'NOT_STARTED';
  }

  // Check status of the logs
  if (questLogs.some((log) => log.status === 'COMPLETED')) {
    return 'COMPLETED';
  } else if (questLogs.some((log) => log.status === 'COMPLETED_WITH_DIFFERENTIATION')) {
    return 'COMPLETED_WITH_DIFFERENTIATION';
  } else if (questLogs.some((log) => log.status === 'IN_PROGRESS')) {
    return 'IN_PROGRESS';
  } else if (questLogs.some((log) => log.status === 'WAITING_ON_VALIDATION')) {
    return 'WAITING_ON_VALIDATION';
  } else if (questLogs.some((log) => log.status === 'FAILED')) {
    return 'FAILED';
  } else {
    return 'NOT_STARTED';
  }
};

const StudentQuest = () => {
  const { t } = useTranslation('global');
  const { questId, courseId } = useParams<{ questId: string; courseId: string }>(); // Id of the quest
  const [pins, setPins] = useState<ImagePin[]>([]); // Contains all the pins of the quest
  const [currentQuestStep, setCurrentQuestStep] = useState<QuestStep | null>(null); // Contains QuestStep of the selected pin
  const [currentQuestStory, setCurrentQuestStory] = useState<Story | null>(null); // Contains Story of the selected pin
  const [modalOpen, setModalOpen] = useState(false); // Defines if the modal is open or closed
  const [startEndModalOpen, setStartEndModalOpen] = useState(false); // Defines if the start/end modal is open or closed
  const [startEndModalData, setStartEndModalData] = useState<BasicTextWizardStepData>({
    type: 'start',
    title: '',
    text: '',
  }); // Contains the text of the start/end

  // Get the current Quest and its state
  const {
    data: quest,
    isLoading: isQuestLoading,
    refetch: refetchQuest,
  } = useQuery(`quest-${questId}`, async () => {
    const quests = await fetchAPI<Quest>(`/quests/${questId}`);
    if (quests.isSuccess) {
      return quests.data;
    } else {
      return null;
    }
  });

  // Get the QuestLogs for this Quest
  const {
    data: questLogs,
    isLoading: isLogsLoading,
    refetch: refetchLogs,
  } = useQuery(`quests-logs-${questId}`, async () => {
    const quests = await fetchAPI<QuestLog>(`/quests/${questId}/logs/${courseId}`);
    if (quests.isSuccess) {
      return quests.data;
    } else {
      return null;
    }
  });

  const startQuest = async () => {
    await fetchAPI(`/quests/${questId}/${courseId}/start`, {
      method: 'PUT',
    });
  };

  useEffect(() => {
    // Set the pins in position
    if (quest) {
      setPins(
        [...startEndPins, ...quest.questSteps]
          .sort((a, b) => a.stepOrder - b.stepOrder)
          .map((s) => {
            return {
              positionX: s.positionX,
              positionY: s.positionY,
              id: String(s.id),
            };
          }),
      );
    }
  }, [quest]);

  // Update the state of the pins based on the logs
  const getPinStatus = (pinId: string) => {
    if (pinId === 'start') {
      return 'START';
    } else if (pinId === 'end') {
      return 'END';
    }

    const logs = questLogs?.questStepLogResponses.filter(
      (log) => log.questStepId === Number(pinId),
    );

    return statusFromQuestStepLogs(logs);
  };

  // Keep track of the first unfinished pin of the Quest
  const isFirstNotStarted = (pinId: string) => {
    if (pins.every((p) => ['NOT_STARTED', 'START', 'END'].includes(getPinStatus(p.id)))) {
      return pinId === 'start';
    }

    const firstNotStartedOrInProgressPin = pins.find((p) => {
      const status = getPinStatus(p.id);
      return (
        status === 'NOT_STARTED' ||
        status === 'IN_PROGRESS' ||
        status === 'WAITING_ON_VALIDATION' ||
        status === 'FAILED' ||
        status === 'END'
      );
    });
    return firstNotStartedOrInProgressPin?.id === pinId;
  };

  const CustomPinComponent = ({ pin }: { pin: ImagePin }) => {
    const [isFirst, setIsFirst] = useState(isFirstNotStarted(pin.id)); // Contains the first unfinished pin
    const [status, setStatus] = useState<QuestLogStatus>(getPinStatus(pin.id)); // Contains the status of the entire quest

    // Update the status of the quest after a pin's state is changed
    useEffect(() => {
      setIsFirst(isFirstNotStarted(pin.id));
      setStatus(getPinStatus(pin.id));
    }, [questLogs, pins]);

    // Set the color of a pin based on its state
    const getColor = (): string => {
      if (status === 'COMPLETED' || status === 'COMPLETED_WITH_DIFFERENTIATION') {
        return 'bg-success';
      } else if (
        status === 'IN_PROGRESS' ||
        status === 'FAILED' ||
        status === 'WAITING_ON_VALIDATION'
      ) {
        return 'bg-info';
      } else if (isFirst) {
        return 'bg-danger';
      } else {
        return 'bg-secondary disabled';
      }
    };

    // Set the icon of a pin based on its state
    const getIcon = (): IconDefinition => {
      if (status === 'COMPLETED' || status === 'COMPLETED_WITH_DIFFERENTIATION') {
        return faCheck;
      } else if (status === 'IN_PROGRESS' || status === 'FAILED') {
        return faFlag;
      } else if (status === 'WAITING_ON_VALIDATION') {
        return faSignature;
      } else {
        return faXmark;
      }
    };

    return (
      <button
        className={`rounded-circle ${getPinBackgroundColor(pin.id, getColor())} shadow-sm`}
        style={{ width: '3em', height: '3em' }}
        disabled={(status === 'NOT_STARTED' || status === 'END') && !isFirstNotStarted(pin.id)}
      >
        <FontAwesomeIcon icon={getPinIcon(pin.id, getIcon())} size='2x' className='text-white' />
      </button>
    );
  };

  // Get the content of the modal and the open it
  const openModal = (pin: ImagePin) => {
    const step = quest?.questSteps.find((qs) => String(qs.id) === pin.id) || null;
    if (step) {
      setCurrentQuestStep(step);
      setCurrentQuestStory(
        quest?.masterStory.stories.at(
          quest?.questSteps.findIndex((qs) => qs.id === currentQuestStep?.id),
        ) || null,
      );
      setModalOpen(true);
    } else if (isIdStartOrEnd(pin.id)) {
      setStartEndModalData({
        type: pin.id === 'start' ? 'start' : 'end',
        title: pin.id === 'start' ? t('introduction') : t('conclusion'),
        text:
          (pin.id === 'start' ? quest?.masterStory.introduction : quest?.masterStory.conclusion) ||
          '',
      });
      setStartEndModalOpen(true);
    }
  };

  // Close the modal and refetch logs and quest to update pins
  const onCloseModal = async () => {
    // Start quest if it's the first time the user opens the start modal
    if (
      startEndModalOpen &&
      startEndModalData.type === 'start' &&
      questLogs?.status === 'NOT_STARTED'
    ) {
      await startQuest();
    }

    setModalOpen(false);
    setStartEndModalOpen(false);
    await refetchLogs();
    await refetchQuest();
  };

  return (
    <div className='h-100 overflow-y-scroll'>
      <div className='m-3'>
        {isQuestLoading || isLogsLoading ? (
          <Loading />
        ) : (
          <>
            <div>
              {quest && (
                <div>
                  <ImagePinContainer
                    image={defaultMap}
                    pins={pins}
                    customPinComponent={(pin) => <CustomPinComponent pin={pin} />}
                    arrow={{ color: '#000' }}
                    onExistingPin={(pin) => openModal(pin)}
                    draggable={false}
                  />
                </div>
              )}
            </div>
            {!!currentQuestStep && !!currentQuestStory && (
              <StudentQuestStepModal
                isOpen={modalOpen}
                onClose={onCloseModal}
                questStep={currentQuestStep}
                story={currentQuestStory}
                updateCompletionStatus={refetchLogs}
                status={getPinStatus(String(currentQuestStep.id))}
              />
            )}
            <StartEndModal
              isOpen={startEndModalOpen}
              onClose={onCloseModal}
              data={startEndModalData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default StudentQuest;
