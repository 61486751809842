import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { fetchAPI, fetchCourse, Participant } from '../../utils/httpRequests';
import { useQuery } from 'react-query';
import { useAuth } from '../../utils/stores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import './CoursePage.css';
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tooltip';
import GiveRewardsModal from '../../components/modals/GiveRewardsModal';

interface NewPasswordBody {
  username: string;
  password: string;
}

interface PasswordResetResponse {
  message: string;
}

const CoursePage = () => {
  const { t } = useTranslation('global');
  const { user, signedIn } = useAuth();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const [selectedTemporaryPassword, setSelectedTemporaryPassword] = useState('********');
  const [passwordSet, setPasswordSet] = useState(false);
  const [error, setError] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(t('copyPassword'));
  const [initialRender, setInitialRender] = useState(true);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [giveRewardsModalOpen, setGiveRewardsModalOpen] = useState(false);
  const [pointsType, setPointsType] = useState<'xp' | 'gold' | 'life'>('xp'); // Starts as XP, but gets changed by whatever button is clicked

  const handleGearClick = (participant: Participant) => {
    setSelectedParticipant(participant);
  };

  useEffect(() => {
    if (!initialRender && selectedTemporaryPassword !== '********') {
      const body = {
        username: selectedParticipant?.user.username || '',
        password: selectedTemporaryPassword,
      };
      const fetchData = async (body: NewPasswordBody): Promise<void> => {
        const response = await fetchAPI<PasswordResetResponse>('/auth/set-new-password', {
          method: 'POST',
          body,
        });
        if (response.isSuccess) {
          setError(false);
        } else {
          setError(true);
        }
        setPasswordSet(true);
        setLoading(false);
      };
      fetchData(body);
    }
  }, [selectedTemporaryPassword]);

  useEffect(() => {
    setInitialRender(false);
  }, []);

  const generateTemporaryPassword = async () => {
    setLoading(true);
    setPasswordCopied(t('copyPassword'));
    await new Promise((resolve) => setTimeout(resolve, 500));
    const temporaryPassword = generateRandomPassword();
    setSelectedTemporaryPassword(temporaryPassword);
  };

  const handleModalClose = () => {
    setSelectedParticipant(null);
    setSelectedTemporaryPassword('********');
    setPasswordSet(false);
    setLoading(false);
  };

  // Function to generate a random password
  const generateRandomPassword = () => {
    const length = Math.floor(Math.random() * (12 - 8 + 1)) + 8; // Random length between 8 and 12
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]:;<>,.?/~\\-123456789';
    const specialChars = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
    let password = '';

    let hasLowerCase = false;
    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecialChar = false;

    while (!hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar) {
      password = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      hasLowerCase = /[a-z]/.test(password);
      hasUpperCase = /[A-Z]/.test(password);
      hasNumber = /[0-9]/.test(password);
      hasSpecialChar = new RegExp(`[${specialChars}]`).test(password);
    }
    return password;
  };

  // Copies generated password to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedTemporaryPassword);
    navigator.clipboard.readText();
    setPasswordCopied(t('passwordCopied'));
  };

  const {
    data: course,
    isLoading,
    refetch,
  } = useQuery(['course', courseId], () => fetchCourse(courseId));
  const participants = course?.participants || [];

  const [searchTerm, setSearchTerm] = useState('');

  // Handle the searchbar
  const filteredParticipants = participants
    .filter((participant) => participant.role === 'STUDENT')
    .filter((participant) => {
      const fullName = participant.user.firstName + ' ' + participant.user.lastName;
      const email = participant.user.email;

      return (
        fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  // Handle search input change and clear selected students
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Update the search term
    setSelectedStudents([]); // Clear the selected students when searching
  };

  // Check if all students are selected
  const areAllSelected =
    filteredParticipants?.length > 0 &&
    filteredParticipants?.every((participant) => selectedStudents.includes(participant.id));

  // Toggle checkbox for a specific student
  const toggleStudentSelection = (participantId: number) => {
    if (selectedStudents.includes(participantId)) {
      setSelectedStudents(selectedStudents.filter((id) => id !== participantId));
    } else {
      setSelectedStudents([...selectedStudents, participantId]);
    }
  };

  // Toggle "Check All" functionality
  const toggleSelectAll = () => {
    if (areAllSelected) {
      setSelectedStudents([]);
    } else {
      const allStudentIds = filteredParticipants?.map((participant) => participant.id);
      setSelectedStudents(allStudentIds ? allStudentIds : []);
    }
  };

  const openGiveRewardsModal = async (type: 'xp' | 'gold' | 'life'): Promise<void> => {
    setPointsType(type);
    setGiveRewardsModalOpen(true);
  };

  const closeGiveRewardsModal = async (): Promise<void> => {
    setGiveRewardsModalOpen(false);
    await refetch();
  };

  // Define a color for each student's circle based on their name
  const getColorFromName = (name: string): string => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the hash to an hsl color
    return `hsl(${hash % 360}, 75%, 50%)`;
  };

  // Handle highlighting the text matching the search term. Color can be changed in css file
  const highlightSearchTerm = (text: string, searchTerm: string) => {
    if (!searchTerm) return text; // If no search term, return the original text

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} className='highlight'>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <>
      <div className='container mw-100'>
        {isLoading || !(course && course.participants) ? (
          <div className='flex align-items-center'>
            {t('loading')}
            <div className='me-2 spinner-border' role='status'>
              <span className='visually-hidden'>{t('loading')}</span>
            </div>
          </div>
        ) : (
          <div>
            <h1>
              {t('courseIntroText')} {course.name}
            </h1>
            {/* Shows name of the teacher for the course */}
            <h3>
              {t('teacher')}:{' '}
              {course.participants.find((p) => p.role === 'TEACHER')?.user.firstName}{' '}
              {course.participants.find((p) => p.role === 'TEACHER')?.user.lastName}
            </h3>

            {/* Page for a TEACHER */}
            {signedIn && user?.teacher ? (
              <div>
                <h1 className='mt-3'>{t('students')}</h1>
                {course.participants.length > 1 ? ( // If more participants than just the teacher
                  <div className='d-flex flex-column' style={{ height: '65vh' }}>
                    <div>
                      <button
                        className='btn btn-primary m-1'
                        disabled={selectedStudents.length === 0}
                        onClick={() => openGiveRewardsModal('xp')}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/xp.png`}
                          alt='XP Icon'
                          className='icon-image'
                        />
                      </button>
                      <button
                        className='btn btn-primary m-1'
                        disabled={selectedStudents.length === 0}
                        onClick={() => openGiveRewardsModal('gold')}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/gold-coins.png`}
                          alt='Or Icon'
                          className='icon-image'
                        />
                      </button>
                      <button
                        className='btn btn-primary m-1'
                        disabled={selectedStudents.length === 0}
                        onClick={() => openGiveRewardsModal('life')}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/heart.png`}
                          alt='Life Icon'
                          className='icon-image'
                        />
                      </button>
                    </div>
                    <div className='my-3'>
                      <input
                        type='search'
                        className='form-control w-25 bg-light border-dark text-dark shadow-sm'
                        placeholder={t('search')}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div className='flex-grow-1' style={{ overflowY: 'auto' }}>
                      <table className='table'>
                        <thead className='sticky'>
                          <tr>
                            {/* "Check All" box */}
                            <th className='w-5'>
                              <input
                                className='form-check-input me-1'
                                type='checkbox'
                                checked={areAllSelected}
                                onChange={toggleSelectAll}
                              />
                            </th>
                            <th>{t('name')}</th>
                            <th>{t('email')}</th>
                            <th>{t('XP')}</th>
                            <th>{t('gold')}</th>
                            <th>{t('life')}</th>
                            <th>{t('actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Shows all STUDENTs in course */}
                          {/* Copy the following template to simulate having more students in a class, for testing
                          {[...filteredParticipants, ...filteredParticipants]?.map(
                            (participant, index) => (
                              <tr key={`${participant.id}-${index}`}>
                              */}
                          {filteredParticipants
                            ?.sort((a, b) =>
                              a.user.lastName
                                .toLowerCase()
                                .localeCompare(b.user.lastName.toLowerCase()),
                            )
                            .map((participant) => (
                              <tr key={participant.id}>
                                {/* Checkbox for each student */}
                                <td className='align-middle'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={selectedStudents.includes(participant.id)}
                                    onChange={() => toggleStudentSelection(participant.id)}
                                  />
                                </td>
                                <td className='align-middle'>
                                  <div className='d-flex align-items-center'>
                                    <div
                                      className='initial-circle me-2'
                                      style={{
                                        backgroundColor: getColorFromName(
                                          participant.user.firstName + participant.user.lastName,
                                        ),
                                      }}
                                    >
                                      {participant.user.firstName.charAt(0)}
                                      {participant.user.lastName.charAt(0)}
                                    </div>
                                    <div>
                                      {highlightSearchTerm(
                                        participant.user.firstName +
                                          ' ' +
                                          participant.user.lastName,
                                        searchTerm,
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className='align-middle'>
                                  {highlightSearchTerm(participant.user.email, searchTerm)}
                                </td>
                                <td className='align-middle'>{participant.xp}</td>
                                <td className='align-middle'>{participant.gold}</td>
                                <td className='align-middle'>{participant.life}</td>
                                <td>
                                  <button
                                    type='button'
                                    className='btn btn-primary align-middle'
                                    data-bs-toggle='modal'
                                    data-bs-target='#staticBackdrop'
                                    onClick={() => handleGearClick(participant)}
                                  >
                                    <FontAwesomeIcon icon={faGear} />
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <p>{t('noStudentsMessage')}</p>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        )}
        {/* Modal to show and manage student info for prof */}
        <div
          className='modal fade'
          id='staticBackdrop'
          tabIndex={-1}
          data-bs-backdrop='static'
          aria-labelledby='staticBackdropLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content'>
              <div className='card user-card-full'>
                <div className='row m-l-0 m-r-0'>
                  <div className='col-sm-4 bg-c-lite-green user-profile p-0'>
                    <div className='card-block text-center text-white'>
                      <div className='m-b-25'>
                        <img
                          src='https://img.icons8.com/bubbles/100/000000/user.png'
                          className='img-radius mx-auto'
                          alt='User-Profile-Image'
                        />
                      </div>
                      <h6 className='f-w-600'>
                        {selectedParticipant?.user.firstName} {selectedParticipant?.user.lastName}
                      </h6>
                      <i className=' mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16'></i>
                    </div>
                  </div>
                  <div className='col-sm-8'>
                    <div className='card-block'>
                      <button
                        type='button'
                        className='btn btn-close close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={handleModalClose}
                      ></button>
                      <h6 className='m-b-20 p-b-5 b-b-default f-w-600'>{t('information')}</h6>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <p className='m-b-10 f-w-600'>{t('email')}</p>
                          <h6 className='text-muted f-w-400'>{selectedParticipant?.user.email}</h6>
                        </div>
                        <div className='col-sm-6'>
                          <p className='m-b-10 f-w-600'>{t('username')}</p>
                          <h6 className='text-muted f-w-400'>
                            {selectedParticipant?.user.username}
                          </h6>
                        </div>
                      </div>
                      <h6 className='m-b-20 m-t-40 p-b-5 b-b-default f-w-600'>
                        {t('setNewPassword')}
                      </h6>
                      <div className='row'>
                        {loading ? (
                          <div className='col-sm-12'>
                            <Loading insideModal={'coursePage'} />
                          </div>
                        ) : (
                          <>
                            {/* Password section */}
                            <div className='col-sm-6'>
                              {passwordSet ? (
                                <>
                                  {error ? (
                                    <>
                                      <div className='alert alert-danger' role='alert'>
                                        {t('newTempPasswordError')}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {/* Shows new password */}
                                      <p className='m-b-10 f-w-600'>{t('newPassword')}</p>
                                      <a
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content={passwordCopied}
                                      >
                                        <h6
                                          className='text-muted f-w-400 pointer'
                                          style={{ display: 'inline' }}
                                          onClick={copyToClipboard}
                                        >
                                          {selectedTemporaryPassword}
                                        </h6>
                                      </a>
                                      <Tooltip id='my-tooltip' />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* Password not Shown */}
                                  <p className='m-b-10 f-w-600'>{t('password')}</p>
                                  <h6 className='text-muted f-w-400 pointer'>********</h6>
                                </>
                              )}
                            </div>
                            <div className='col-sm-6'>
                              {/* Button to generate new password */}
                              <button
                                className='btn btn-primary btn-sm'
                                onClick={generateTemporaryPassword}
                              >
                                {t('genNewPassword')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GiveRewardsModal
        isOpen={giveRewardsModalOpen}
        onClose={closeGiveRewardsModal}
        pointsType={pointsType}
        participantList={selectedStudents}
      />
    </>
  );
};

export default CoursePage;
