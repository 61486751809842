import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faMap, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SideBar = () => {
  const { t } = useTranslation('global');
  const location = useLocation();
  const { courseId } = useParams<{ courseId?: string }>();

  const isActiveLink = (path: string) => {
    const regex = new RegExp(`^${path}(/[0-9]+)?$`);
    return regex.test(location.pathname);
  };

  return (
    <>
      <div
        className='d-flex flex-column flex-shrink-0 p-3 pt-2 text-white bg-dark h-100'
        style={{ width: '170px' }}
      >
        <ul className='nav nav-pills flex-column mb-auto'>
          <li className='nav-item'>
            <Link
              to={'/courses/' + courseId}
              className={`nav-link ${isActiveLink(`/courses/${courseId}`) ? 'active' : 'text-white'}`}
              aria-current='page'
            >
              <FontAwesomeIcon icon={faUsers} style={{ marginRight: '0.5rem' }} />
              {t('class')}
            </Link>
          </li>
          <li>
            <Link
              to={`/courses/${courseId}/quests`}
              className={`nav-link ${isActiveLink(`/courses/${courseId}/quests`) ? 'active' : 'text-white'}`}
            >
              <FontAwesomeIcon icon={faMap} style={{ marginRight: '0.5rem' }} />
              {t('quests')}
            </Link>
          </li>
        </ul>
        <hr />
        <ul className='nav nav-pills'>
          <li className='nav-item'>
            <Link
              to={`/courses/${courseId}/settings`}
              className={`nav-link ${isActiveLink(`/courses/${courseId}/settings`) ? 'active' : 'text-white'}`}
            >
              <FontAwesomeIcon icon={faGear} style={{ marginRight: '0.5rem' }} />
              {t('settings')}
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
