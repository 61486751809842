import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CreatedResponse, fetchAPI, UpdatedResponse } from '../../../../utils/httpRequests';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Course, MasterStory, Quest } from '../Quests';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Loading from '../../../../components/Loading';
import Select, { GroupBase } from 'react-select';
import { MenuListProps } from 'react-select';
import { JSX } from 'react/jsx-runtime';
import { components } from 'react-select';
import MasterStoryModal from '../../stories/MasterStoryModal';

interface CreateQuestModalProps {
  isOpen: boolean; // State of the modal
  onClose: (id?: number | null) => void; // Closing function
  editQuest?: Quest | null; // Defines if we edit or create a quest
}

interface CreateQuestForm extends FieldValues {
  name: string;
  masterStory: { value: number; label: string };
  courses: { value: number; label: string }[];
}

interface CreateQuestSchema {
  name: string;
  masterStoryId: number;
  defaultOptional: boolean;
  courseIds: number[];
}

const CreateQuestModal = ({ isOpen, onClose, editQuest }: CreateQuestModalProps) => {
  const { t } = useTranslation('global');
  const [openMasterStoryModal, setOpenMasterStoryModal] = useState<boolean>(false);

  const newMasterStory = () => {
    setOpenMasterStoryModal(true);
  };

  const closeMasterStoryModal = async (id: number | null) => {
    if (id) {
      const ms = await refetchMasterStories();
      const masterStoryName = ms.data?.find((m) => m.id === id)?.name;
      setValue('masterStory', { value: id, label: masterStoryName ?? '' });
    }
    setOpenMasterStoryModal(false);
  };

  const SelectMenuButton = (
    props: JSX.IntrinsicAttributes & MenuListProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <button onClick={newMasterStory} className='fw-bold py-1' style={{ paddingLeft: '12px' }}>
          <FontAwesomeIcon icon={faPlus} className='me-2' />
          {t('addANewMasterStory')}
        </button>
      </components.MenuList>
    );
  };

  const createQuest = async (data: CreateQuestForm) => {
    const body: CreateQuestSchema = {
      name: data.name,
      masterStoryId: data.masterStory.value,
      defaultOptional: false,
      courseIds: data.courses?.map((c) => c.value) ?? [],
    };

    const response = await fetchAPI<CreatedResponse>('/quests', {
      method: 'POST',
      body,
    });

    if (response.isSuccess && response.data.created) {
      closeModal(response.data.created);
    }
  };

  const updateQuest = async (data: CreateQuestForm) => {
    const body: CreateQuestSchema = {
      name: data.name,
      masterStoryId: data.masterStory.value,
      defaultOptional: false,
      courseIds: data.courses.map((c) => c.value),
    };

    const response = await fetchAPI<UpdatedResponse>(`/quests/${editQuest?.id}`, {
      method: 'PUT',
      body,
    });

    if (response.isSuccess && response.data.updated) {
      closeModal();
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<CreateQuestForm>();

  useEffect(() => {
    // Set the values of the form to what was saved
    if (editQuest) {
      reset({
        name: editQuest.name,
        masterStory: { value: editQuest.masterStory.id, label: editQuest.masterStory.name },
        courses: editQuest.courses.map((c) => {
          return { value: c.id, label: c.name };
        }),
      });
    }
  }, [editQuest, reset]);

  // Resetting values and closing the modal
  const closeModal = (id?: number | null): void => {
    reset({
      name: '',
      introduction: '',
    });
    onClose(id);
  };

  // Get all Master Stories for the select input
  const {
    data: masterStories,
    isLoading: isMasterStoriesLoading,
    refetch: refetchMasterStories,
  } = useQuery('masterStories', async () => {
    const masterStories = await fetchAPI<MasterStory[]>(`/master-stories`);
    if (masterStories.isSuccess) {
      return masterStories.data;
    } else {
      return null;
    }
  });

  // Get all courses for the select input
  const { data: courses, isLoading: isCoursesLoading } = useQuery('courses', async () => {
    const courses = await fetchAPI<Course[]>(`/courses`);
    if (courses.isSuccess) {
      return courses.data;
    } else {
      return null;
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      contentLabel='Create/Update Quest Modal'
    >
      <div className='w-100 h-100'>
        <div className='d-flex w-100 justify-content-between'>
          <h2>{t(`quests.${editQuest ? 'update' : 'create'}`)}</h2>
          <button onClick={() => closeModal()} className='btn btn-primary'>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>

        {isMasterStoriesLoading || isCoursesLoading ? (
          <Loading />
        ) : (
          <form onSubmit={handleSubmit(editQuest ? updateQuest : createQuest)}>
            <div className='mb-3'>
              <label htmlFor='questName' className='form-label'>
                {t('quests.name')}
              </label>
              <input
                type='text'
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                id='questName'
                placeholder='My amazing quest'
                {...register('name', { required: true })}
              />
              {errors.name && <span className='invalid-feedback'>{t('quests.name_required')}</span>}
            </div>
            <div className='mb-3'>
              <label className='form-label'>{t('master_story')}</label>
              {masterStories?.length ? (
                <div>
                  <Controller
                    name='masterStory'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        components={{
                          MenuList: SelectMenuButton,
                        }}
                        className={`${errors.masterStory ? 'is-invalid' : ''}`}
                        options={masterStories.map((m) => {
                          return { value: m.id, label: m.name };
                        })}
                      />
                    )}
                  />
                  {errors.concept && (
                    <span className='invalid-feedback'>
                      {t('quest_steps.master_story_required')}
                    </span>
                  )}
                </div>
              ) : (
                <div className='alert alert-warning'>
                  {t('quest_steps.master_story_not_available')}
                </div>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>{t('linked_courses')}</label>
              {courses?.length ? (
                <div>
                  <Controller
                    name='courses'
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti={true}
                        isClearable={true}
                        className={`${errors.courses ? 'is-invalid' : ''}`}
                        options={courses.map((c) => {
                          return { value: c.id, label: c.name };
                        })}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className='alert alert-warning'>{t('quest_steps.courses_not_available')}</div>
              )}
            </div>
            <button type='submit' className='btn btn-primary'>
              {editQuest ? t('quests.update') : t('quests.create')}
            </button>
          </form>
        )}
      </div>
      <MasterStoryModal isOpen={openMasterStoryModal} onClose={closeMasterStoryModal} />
    </Modal>
  );
};

Modal.setAppElement('#root');

export default CreateQuestModal;
