import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/sign-in/Login';
import Welcome from './pages/signed-in/Welcome';
import SignUpForm from './pages/auth/sign-up/SignUpForm';
import { ProtectedRoute } from './components/ProtectedRoutes';
import AccountType from './pages/auth/sign-up/AccountType';
import Navbar from './components/Navbar';
import { validateAndRefreshCurrentToken } from './utils/auth';
import { useAuth } from './utils/stores';
import ConceptsPage from './pages/teacher/concepts/ConceptsPage';
import { TeacherOnlyRoutes } from './components/TeacherOnlyRoutes';
import CoursePage from './pages/signed-in/CoursePage';
import Quests from './pages/teacher/quests/Quests';
import NotFound from './pages/common/NotFound';
import QuestPage from './pages/teacher/quests/QuestPage';
import Course from './pages/signed-in/Course';
import StudentQuests from './pages/student/StudentQuests';
import StudentQuest from './pages/student/StudentQuest';
import StoriesPage from './pages/teacher/stories/StoriesPage';
import ResetPassword from './pages/auth/reset-password/ResetPassword';
import Settings from './pages/signed-in/Settings';
import QuestSubmissions from './pages/teacher/quests/QuestSubmissions';
import QuestSubmission from './pages/teacher/quests/QuestSubmission';
import CourseSettings from './pages/teacher/configuration/Settings';
import AvatarSelection from './pages/student/participants/AvatarSelection';

const App = () => {
  const { setLoading, setSignedIn, setUser, signedIn } = useAuth();

  useEffect(() => {
    // Check if the user's connection is still valid
    setLoading(true);
    validateAndRefreshCurrentToken().then((r) => {
      const user = localStorage.getItem('user');
      if (r && user) {
        setSignedIn(true);
        setUser(JSON.parse(user));
      } else {
        setSignedIn(false);
        setUser(null);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div className='d-flex flex-column vh-100'>
      <Navbar />
      <div className='flex-grow-1 overflow-auto'>
        <Routes>
          <Route path='/' element={signedIn ? <Welcome /> : <Home />} />
          <Route path='/auth/sign-in' element={<Login />} />
          <Route path='/auth/sign-up' element={<AccountType />} />
          <Route path='/auth/sign-up/:role' element={<SignUpForm />} />
          <Route path='/auth/reset-password' element={<ResetPassword />} />
          <Route element={<ProtectedRoute />}>
            {/** These routes are protected, meaning you need to be signed in to access them **/}
            <Route path='/welcome' element={<Welcome />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='courses' element={<Course />}>
              <Route path=':courseId' element={<CoursePage />} />
              <Route path=':courseId/avatar/:participantId' element={<AvatarSelection />} />
              <Route path=':courseId/quests' element={<StudentQuests />} />
              <Route path=':courseId/quests/:questId' element={<StudentQuest />} />
              <Route element={<TeacherOnlyRoutes />}>
                <Route path=':courseId/settings' element={<CourseSettings />} />
                <Route
                  path=':courseId/quests/:questId/submissions'
                  element={<QuestSubmissions />}
                />
                <Route
                  path=':courseId/quests/:questId/submissions/:participantId'
                  element={<QuestSubmission />}
                />
              </Route>
            </Route>
          </Route>
          <Route element={<TeacherOnlyRoutes />}>
            <Route path='/stories' element={<StoriesPage />} />
            <Route path='/concepts' element={<ConceptsPage />} />
            <Route path='/quests' element={<Quests />} />
            <Route path='/quests/:questId' element={<QuestPage />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
